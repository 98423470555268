exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brest-js": () => import("./../../../src/pages/brest.js" /* webpackChunkName: "component---src-pages-brest-js" */),
  "component---src-pages-gomel-js": () => import("./../../../src/pages/gomel.js" /* webpackChunkName: "component---src-pages-gomel-js" */),
  "component---src-pages-grodno-js": () => import("./../../../src/pages/grodno.js" /* webpackChunkName: "component---src-pages-grodno-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mogilev-js": () => import("./../../../src/pages/mogilev.js" /* webpackChunkName: "component---src-pages-mogilev-js" */),
  "component---src-pages-vitebsk-js": () => import("./../../../src/pages/vitebsk.js" /* webpackChunkName: "component---src-pages-vitebsk-js" */)
}

